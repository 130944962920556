import { defineStore } from 'pinia'

import { name, version } from '../../package.json'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useConfigStore = defineStore('config', {
  persist: {
    key: `${name}-config-${version.slice(0, version.length - 2)}.x`,
    paths: ['staticMenuDesktopInactive', 'staticMenuMobileActive']
  },

  state: () => ({
		staticMenuDesktopInactive: false,
    staticMenuMobileActive: false
  }),

  actions: {
    banListsbanId: (data) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/configs/ban-lists`, data),
		index: () => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/configs`),
		show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/configs/${id}`),
		update: (id, config) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/configs/${id}`, config),
  }
})
