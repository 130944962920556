<template>
    <div class="layout-footer">
        <div class="footer-logo-container">
            <img id="footer-logo" src="@/assets/logo.png" alt="diamond-layout" />
            <span class="app-name">UHAS</span>
        </div>
        <span class="copyright">&#169; UHAS - 2022</span>
    </div>
</template>

<script>
export default {
    name: "AppFooter"
};
</script>