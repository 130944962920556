import { createPinia } from 'pinia'
const pinia = createPinia()

import { createPersistedState } from 'pinia-plugin-persistedstate'
pinia.use((context) => {
  let options = {}
  if (context.options.persist) {
    //
  }
  createPersistedState(options)(context)
})

export default pinia
