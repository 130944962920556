<script setup>
  import { ref } from "vue"
  import { useAuthStore } from "@/store/auth"
  import { useToast } from "primevue/usetoast"
  import router from "@/router"

  import { v4 as uuidv4 } from 'uuid'

  const fpPromise = import('@fingerprintjs/fingerprintjs').then(FingerprintJS => FingerprintJS.load())

  const authStore = useAuthStore()
  const toast = useToast()

  const username = ref(null)
  const password = ref(null)

  if (process.env.NODE_ENV === 'development') {
    username.value = 'owner'
    password.value = 'password'
  }

  const loading = ref(false)

  const signIn = (username, password) => {
    loading.value = true

    fpPromise
      .then(fp => fp.get())
      .then(result => {
        authStore.setUUID(uuidv4())
        authStore.login({ username, password, token: result.visitorId }).then(({ data }) => {
          if (data.error) {
            toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
            loading.value = false
            return
          }

          const { accessToken, token } = data.user
          authStore.setToken({ accessToken, token })

          toast.add({severity:'success', summary: 'Success', detail: 'Login Successful.', life: 3000})

          router.replace('dashboard')
          
        }).catch((e) => {
          toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
        })
      })
  }
</script>

<template>
  <div class="login-body">
    <Toast />

    <div class="login-wrapper">
      <div class="login-panel">
        <img src="@/assets/logo.png" class="logo" alt="diamond-layout" />

        <div class="login-form">
          <h2>Login</h2>
          <InputText v-model="username" placeholder="Username" />
          <Password v-model="password" placeholder="Password" style="width: 85%"/>
          <Button 
            :disabled="loading"
            label="LOGIN" 
            :loading="loading"
            type="button"
            @click="signIn(username, password)"
          />
        </div>

        <br/>
      </div>
      <div class="login-image">
        <div class="login-image-content">
          <h1>UHAS.COM</h1>
          <h1>Membership</h1>
          <h1>Account</h1>
          <h3>
            Lorem ipsum dolor sit amet, consectetur <br />
            adipiscing elit. Donec posuere velit nec enim <br />
            sodales, nec placerat erat tincidunt.
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>
