import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

import { name, version } from '../../package.json'

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useAuthStore = defineStore('auth', {
  persist: {
    key: `${name}-auth-${version.slice(0, version.length - 2)}.x`,
    paths: ['accessToken', 'user', 'uuid']
  },

  state: () => ({
    accessToken: null,
    logged: false,
    permissions: [],
    token: {},
    user: {},
    uuid: null,
  }),
  actions: {
    getAuth: () => axios.get(VUE_APP_ENDPOINT_URL + '/api/auth/get'),

    getPermissions: () => axios.get(`${VUE_APP_ENDPOINT_URL}/api/auth/permissions`),

    hasPermission (permission) {
      return this.user.role == 'owner' || this.permissions.includes(permission)
    },

    login ({ username, password, token }) {
      return axios.post(VUE_APP_ENDPOINT_URL + '/api/login', { username, password }, { headers: { 'token': token + this.uuid } })
    },

    logout () {
      const accessToken = null
      const token = {}
      this.setToken({ accessToken, token })

      this.logged = false
      this.user = {}
    },

    setAxios ({ accessToken }) {
      axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` }
    },

    setLogged () {
      this.logged = true
    },

    setPermissions (permissions) {
      delete permissions['owner']
      this.permissions = Object.keys(permissions)
    },

    setToken ({ accessToken, token }) {
      this.accessToken = accessToken
      this.token = token

      this.setAxios({ accessToken })
    },

    setUser (user) {
      this.user = user
    },

    setUUID (uuid) {
      if (! this.uuid)
        this.uuid = uuid.replace(/-/g, '')
    }
  }
})
