import {createRouter, createWebHistory} from 'vue-router';

import { useAuthStore } from "@/store/auth"

const routes = [
  {
    path: '/',
    name: 'blank',
    exact: true,
    component: () => {}
  },
  { path: '/logout', name: 'logout', component: () => import('./uhas/pages/Blank.vue') },

	{
    path: '/accounts',
    name: 'accounts',
    exact: true,
    component: () => import('./uhas/pages/accounts/Index.vue'),
    meta: { breadcrumb: [{ parent: 'Generals', label: 'Accounts' }], },
  },
  {
    path: '/admins',
    name: 'admins',
    exact: true,
    component: () => import('./uhas/pages/admins/Index.vue'),
    meta: { breadcrumb: [{ parent: 'Users', label: 'Admins' }], },
  },
  {
    path: '/admins/create',
    name: 'admins.create',
    exact: true,
    component: () => import('./uhas/pages/admins/Create.vue'),
    meta: { breadcrumb: [{ parent: 'Users', label: 'Admins' }], },
  },
  {
    path: '/admins/:id/edit',
    name: 'admins.edit',
    exact: true,
    component: () => import('./uhas/pages/admins/Edit.vue'),
    meta: { breadcrumb: [{ parent: 'Users', label: 'Admins' }], },
  },
  {
    path: '/admins/:id/statistic',
    name: 'admins.statistic',
    exact: true,
    component: () => import('./uhas/pages/admins/Statistic.vue'),
    meta: { breadcrumb: [{ parent: 'Users', label: 'Admins' }], },
  },

  {
    path: '/activities',
    name: 'activities',
    exact: true,
    component: () => import('./uhas/pages/activities/Index.vue'),
    meta: { breadcrumb: [{ parent: 'Generals', label: 'Activities' }], },
  },
  {
    path: '/activities/create',
    name: 'activities.create',
    exact: true,
    component: () => import('./uhas/pages/activities/Edit.vue'),
    meta: { breadcrumb: [{ parent: 'Generals', label: 'Activities' }], },
  },
  {
    path: '/activities/:id/edit',
    name: 'activities.edit',
    exact: true,
    component: () => import('./uhas/pages/activities/Edit.vue'),
    meta: { breadcrumb: [{ parent: 'Generals', label: 'Activities' }], },
  },
	{
    path: '/activities/:id',
    name: 'activities.show',
    exact: true,
    component: () => import('./uhas/pages/activities/Edit.vue'),
    meta: { breadcrumb: [{ parent: 'Generals', label: 'Activities', readonly: true }], },
  },

	{
    path: '/activities/:id/members',
    name: 'activities.show.members',
    exact: true,
    component: () => import('./uhas/pages/activities/ShowMembers.vue'),
    meta: { breadcrumb: [{ parent: 'Activity', label: 'Activities Members' }], },
  },
	{
    path: '/activities/:id/members/:memberId/edit',
    name: 'activities.edit.members',
    exact: true,
    component: () => import('./uhas/pages/activities/EditMembers.vue'),
    meta: { breadcrumb: [{ parent: 'Activity', label: 'Activities Members Edit' }], },
  },

	{
    path: '/axis',
    name: 'axis',
    exact: true,
    component: () => import('./uhas/pages/axis/Index.vue'),
    meta: { breadcrumb: [{ parent: 'Forex', label: 'AXIs' }], },
  },
  {
    path: '/axis/:id',
    name: 'axis.show',
    exact: true,
    component: () => import('./uhas/pages/axis/Show.vue'),
    meta: { breadcrumb: [{ parent: 'Forex', label: 'AXIs' }], },
  },

  {
    path: '/community-chats',
    name: 'community-chats',
    exact: true,
    component: () => import('./uhas/pages/community-chats/Index.vue'),
    meta: { breadcrumb: [{ parent: 'Group Chat', label: 'Group Chat Community' }], },
  },
  {
    path: '/community-chats/:id/edit',
    name: 'community-chats.edit',
    exact: true,
    component: () => import('./uhas/pages/community-chats/Edit.vue'),
    meta: { breadcrumb: [{ parent: 'Group Chat', label: 'Group Chat Community' }], },
  },
  {
    path: '/community-chats/:id',
    name: 'community-chats.show',
    exact: true,
    component: () => import('./uhas/pages/community-chats/Show.vue'),
    meta: { breadcrumb: [{ parent: 'Group Chat', label: 'Group Chat Community' }], },
  },
  {
    path: '/community-chats/create',
    name: 'community-chats.create',
    exact: true,
    component: () => import('./uhas/pages/community-chats/Edit.vue'),
    meta: { breadcrumb: [{ parent: 'Group Chat', label: 'Group Chat Community' }], },
  },

  {
    path: '/courses',
    name: 'courses',
    exact: true,
    component: () => import('./uhas/pages/courses/Index.vue'),
    meta: { breadcrumb: [{ parent: 'Courses', label: 'Courses' }], },
  },
  {
    path: '/courses/create',
    name: 'courses.create',
    exact: true,
    component: () => import('./uhas/pages/courses/Edit.vue'),
    meta: { breadcrumb: [{ parent: 'Courses', label: 'Courses' }], },
  },
  {
    path: '/courses/:id/edit',
    name: 'courses.edit',
    exact: true,
    component: () => import('./uhas/pages/courses/Edit.vue'),
    meta: { breadcrumb: [{ parent: 'Courses', label: 'Courses' }], },
  },
	{
    path: '/courses/:id',
    name: 'courses.show',
    exact: true,
    component: () => import('./uhas/pages/courses/Show.vue'),
    meta: { breadcrumb: [{ parent: 'Courses', label: 'Courses', readonly: true }], },
  },
  {
    path: '/courses/members',
    name: 'courses.members.index',
    exact: true,
    component: () => import('./uhas/pages/courses/IndexMembers.vue'),
    meta: { breadcrumb: [{ parent: 'Courses', label: 'Courses Members' }], },
  },
  {
    path: '/courses/:courseId/sessions',
    name: 'courses.sessions.create',
    exact: true,
    component: () => import('./uhas/pages/courses/sessions/Edit.vue'),
    meta: { breadcrumb: [{ parent: 'Courses', label: 'Courses Session' }], },
  },
  {
    path: '/courses/sessions/:id/edit',
    name: 'courses.sessions.edit',
    exact: true,
    component: () => import('./uhas/pages/courses/sessions/Edit.vue'),
    meta: { breadcrumb: [{ parent: 'Courses', label: 'Courses Session' }], },
  },

  {
    path: '/configs/ban-lists',
    name: 'configs/ban-lists',
    exact: true,
    component: () => import('./uhas/pages/configs/BanLists.vue'),
    meta: { breadcrumb: [{ parent: 'Configs', label: 'Config Ban Lists' }], },
  },
  {
    path: '/configs/forex',
    name: 'configs/forex',
    exact: true,
    component: () => import('./uhas/pages/configs/Forex.vue'),
    meta: { breadcrumb: [{ parent: 'Configs', label: 'Config Forex' }], },
  },
  {
    path: '/configs/tags',
    name: 'configs/tags',
    exact: true,
    component: () => import('./uhas/pages/configs/Tags.vue'),
    meta: { breadcrumb: [{ parent: 'Configs', label: 'Config Tags' }], },
  },
  {
    path: '/configs/uhas',
    name: 'configs/uhas',
    exact: true,
    component: () => import('./uhas/pages/configs/Uhas.vue'),
    meta: { breadcrumb: [{ parent: 'Configs', label: 'Config Uhas' }], },
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    exact: true,
    component: () => import('./uhas/pages/Dashboard.vue'),
    meta: { breadcrumb: [{ parent: 'Dashboard', label: 'Dashboard' }], },
  },

  {
    path: '/ea-requests',
    name: 'ea-requests',
    exact: true,
    component: () => import('./uhas/pages/ea-requests/Index.vue'),
    meta: { breadcrumb: [{ parent: 'Forex', label: 'Exnesses' }], },
  },
  {
    path: '/ea-requests/:id',
    name: 'ea-requests.show',
    exact: true,
    component: () => import('./uhas/pages/ea-requests/Show.vue'),
    meta: { breadcrumb: [{ parent: 'Forex', label: 'Exnesses' }], },
  },

  {
    path: '/exnesses',
    name: 'exnesses',
    exact: true,
    component: () => import('./uhas/pages/exnesses/Index.vue'),
    meta: { breadcrumb: [{ parent: 'Forex', label: 'Exnesses' }], },
  },
  {
    path: '/exnesses/:id',
    name: 'exnesses.show',
    exact: true,
    component: () => import('./uhas/pages/exnesses/Show.vue'),
    meta: { breadcrumb: [{ parent: 'Forex', label: 'Exnesses' }], },
  },

  {
    path: '/fbss',
    name: 'fbss',
    exact: true,
    component: () => import('./uhas/pages/fbss/Index.vue'),
    meta: { breadcrumb: [{ parent: 'Forex', label: 'FBSs' }], },
  },
  {
    path: '/fbss/:id',
    name: 'fbss.show',
    exact: true,
    component: () => import('./uhas/pages/fbss/Show.vue'),
    meta: { breadcrumb: [{ parent: 'Forex', label: 'FBSs' }], },
  },

	{
    path: '/fxgts',
    name: 'fxgts',
    exact: true,
    component: () => import('./uhas/pages/fxgts/Index.vue'),
    meta: { breadcrumb: [{ parent: 'Forex', label: 'FXGTs' }], },
  },
  {
    path: '/fxgts/:id',
    name: 'fxgts.show',
    exact: true,
    component: () => import('./uhas/pages/fxgts/Show.vue'),
    meta: { breadcrumb: [{ parent: 'Forex', label: 'FXGTs' }], },
  },

  {
    path: '/hfms',
    name: 'hfms',
    exact: true,
    component: () => import('./uhas/pages/hfms/Index.vue'),
    meta: { breadcrumb: [{ parent: 'Forex', label: 'HFMs' }], },
  },
  {
    path: '/hfms/:id',
    name: 'hfms.show',
    exact: true,
    component: () => import('./uhas/pages/hfms/Show.vue'),
    meta: { breadcrumb: [{ parent: 'Forex', label: 'HFMs' }], },
  },

  {
    path: '/frontend/dashboard',
    name: 'frontend.dashboard',
    exact: true,
    component: () => import('./uhas/pages/frontend/Dashboard.vue'),
    meta: { breadcrumb: [{ parent: 'Frontend', label: 'Dashboard' }], },
  },

  {
    path: '/official-chats',
    name: 'official-chats',
    exact: true,
    component: () => import('./uhas/pages/official-chats/Index.vue'),
    meta: { breadcrumb: [{ parent: 'Group Chat', label: 'Group Chat Official' }], },
  },
  {
    path: '/official-chats/:id/edit',
    name: 'official-chats.edit',
    exact: true,
    component: () => import('./uhas/pages/official-chats/Edit.vue'),
    meta: { breadcrumb: [{ parent: 'Group Chat', label: 'Group Chat Official' }], },
  },
  {
    path: '/official-chats/:id',
    name: 'official-chats.show',
    exact: true,
    component: () => import('./uhas/pages/official-chats/Show.vue'),
    meta: { breadcrumb: [{ parent: 'Group Chat', label: 'Group Chat Official' }], },
  },
  {
    path: '/official-chats/create',
    name: 'official-chats.create',
    exact: true,
    component: () => import('./uhas/pages/official-chats/Edit.vue'),
    meta: { breadcrumb: [{ parent: 'Group Chat', label: 'Group Chat Official' }], },
  },
  {
    path: '/official-chats/:groupChatId/messages',
    name: 'official-chats.messages.create',
    exact: true,
    component: () => import('./uhas/pages/official-chats/messages/Edit.vue'),
    meta: { breadcrumb: [{ parent: 'Group Chat', label: 'Group Chat Official Message' }], },
  },
  {
    path: '/official-chats/messages/:id/edit',
    name: 'official-chats.messages.edit',
    exact: true,
    component: () => import('./uhas/pages/official-chats/messages/Edit.vue'),
    meta: { breadcrumb: [{ parent: 'Group Chat', label: 'Group Chat Official Message' }], },
  },

  {
    path: '/members',
    name: 'members',
    exact: true,
    component: () => import('./uhas/pages/members/Index.vue'),
    meta: { breadcrumb: [{ parent: 'Users', label: 'Members' }], },
  },
  {
    path: '/members/levels',
    name: 'members.levels',
    exact: true,
    component: () => import('./uhas/pages/members/Level.vue'),
    meta: { breadcrumb: [{ parent: 'Users', label: 'Members' }], },
  },
  {
    path: '/members/:id',
    name: 'members.show',
    exact: true,
    component: () => import('./uhas/pages/members/Show.vue'),
    meta: { breadcrumb: [{ parent: 'Users', label: 'Members' }], },
  },

  {
    path: '/xms',
    name: 'xms',
    exact: true,
    component: () => import('./uhas/pages/xms/Index.vue'),
    meta: { breadcrumb: [{ parent: 'Forex', label: 'Xms' }], },
  },
  {
    path: '/xms/:id',
    name: 'xms.show',
    exact: true,
    component: () => import('./uhas/pages/xms/Show.vue'),
    meta: { breadcrumb: [{ parent: 'Forex', label: 'Xms' }], },
  },



  //

  {
    path: '/dashboard.dev',
    name: 'dashboard.dev',
    exact: true,
    component: () => import('./components/Dashboard.vue'),
    meta: {
        breadcrumb: [{ parent: 'Dashboard', label: 'Dashboard' }],
    },
  },
  {
    path: '/formlayout',
    name: 'formlayout',
    component: () => import('./components/FormLayoutDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'UI Kit', label: 'Form Layout' }],
    },
  },
  {
    path: '/input',
    name: 'input',
    component: () => import('./components/InputDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'UI Kit', label: 'Input' }],
    },
  },
  {
    path: '/floatlabel',
    name: 'floatlabel',
    component: () => import('./components/FloatLabelDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'UI Kit', label: 'Float Label' }],
    },
  },
  {
    path: '/invalidstate',
    name: 'invalidstate',
    component: () => import('./components/InvalidStateDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'UI Kit', label: 'Invalid State' }],
    },
  },
  {
    path: '/button',
    name: 'button',
    component: () => import('./components/ButtonDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'UI Kit', label: 'Button' }],
    },
  },
  {
      path: '/table',
      name: 'table',
      component: () => import('./components/TableDemo.vue'),
      meta: {
          breadcrumb: [{ parent: 'UI Kit', label: 'Table' }],
      },
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('./components/ListDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'UI Kit', label: 'List' }],
    },
  },
  {
    path: '/tree',
    name: 'tree',
    component: () => import('./components/TreeDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'UI Kit', label: 'Tree' }],
    },
  },
  {
    path: '/panel',
    name: 'panel',
    component: () => import('./components/PanelsDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'UI Kit', label: 'Panel' }],
    },
  },
  {
    path: '/overlay',
    name: 'overlay',
    component: () => import('./components/OverlayDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'UI Kit', label: 'Overlay' }],
    },
  },
  {
    path: '/media',
    name: 'media',
    component: () => import('./components/MediaDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'UI Kit', label: 'Media' }],
    },
},
  {
    path: '/menu',
    component: () => import('./components/MenuDemo.vue'),
    children: [
      {
          path: '',
          component: () => import('./components/menu/PersonalDemo.vue'),
          meta: {
              breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
          },
      },
      {
          path: '/menu/seat',
          component: () => import('./components/menu/SeatDemo.vue'),
          meta: {
              breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
          },
      },
      {
          path: '/menu/payment',
          component: () => import('./components/menu/PaymentDemo.vue'),
          meta: {
              breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
          },
      },
      {
          path: '/menu/confirmation',
          component: () => import('./components/menu/ConfirmationDemo.vue'),
          meta: {
              breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
          },
      },
    ],
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import('./components/MessagesDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'UI Kit', label: 'Messages' }],
    },
  },
  {
    path: '/file',
    name: 'file',
    component: () => import('./components/FileDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'UI Kit', label: 'File' }],
    },
  },
  {
    path: '/chart',
    name: 'chart',
    component: () => import('./components/ChartDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'UI Kit', label: 'Charts' }],
    },
  },
  {
    path: '/misc',
    name: 'misc',
    component: () => import('./components/MiscDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'UI Kit', label: 'Misc' }],
    },
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('./utilities/Icons.vue'),
    meta: {
        breadcrumb: [{ parent: 'Utilities', label: 'Icons' }],
    },
  },
  {
    path: '/widgets',
    name: 'widgets',
    component: () => import('./utilities/Widgets.vue'),
    meta: {
        breadcrumb: [{ parent: 'Utilities', label: 'Widgets' }],
    },
  },
  {
    path: '/grid',
    name: 'grid',
    component: () => import('./utilities/GridDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'Utilities', label: 'Grid System' }],
    },
  },
  {
    path: '/spacing',
    name: 'spacing',
    component: () => import('./utilities/SpacingDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'Utilities', label: 'Spacing' }],
    },
  },
  {
    path: '/elevation',
    name: 'elevation',
    component: () => import('./utilities/ElevationDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'Utilities', label: 'Elevation' }],
    },
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('./utilities/Typography.vue'),
    meta: {
        breadcrumb: [{ parent: 'Utilities', label: 'Typography' }],
    },
  },
  {
    path: '/display',
    name: 'display',
    component: () => import('./utilities/DisplayDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'Utilities', label: 'Display' }],
    },
  },
  {
    path: '/flexbox',
    name: 'flexbox',
    component: () => import('./utilities/FlexBoxDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'Utilities', label: 'Flexbox' }],
    },
  },
  {
    path: '/text',
    name: 'text',
    component: () => import('./utilities/TextDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'Utilities', label: 'Text' }],
    },
  },
  {
    path: '/crud',
    name: 'crud',
    component: () => import('./pages/CrudDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'Pages', label: 'Crud' }],
    },
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('./pages/CalendarDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'Pages', label: 'Calendar' }],
    },
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: () => import('./pages/TimelineDemo.vue'),
    meta: {
        breadcrumb: [{ parent: 'Pages', label: 'Timeline' }],
    },
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: () => import('./pages/Invoice.vue'),
    meta: {
        breadcrumb: [{ parent: 'Pages', label: 'Invoice' }],
    },
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('./pages/Help.vue'),
    meta: {
        breadcrumb: [{ parent: 'Pages', label: 'Help' }],
    },
  },
  {
    path: '/empty',
    name: 'empty',
    component: () => import('./components/EmptyPage.vue'),
    meta: {
        breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }],
    },
  },
  {
    path: '/documentation',
    name: 'documentation',
    component: () => import('./components/Documentation.vue'),
    meta: {
        breadcrumb: [{ parent: 'Pages', label: 'Documentation' }],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./pages/Login.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('./pages/Error.vue')
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: () => import('./pages/NotFound.vue')
  },
  {
    path: '/access',
    name: 'access',
    component: () => import('./pages/Access.vue')
  },
  {
    path: '/blocks',
    name: 'blocks',
    component: () => import('./components/BlocksDemo.vue')
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, _from, next) => {
  const authStore = useAuthStore()

  if (authStore.logged) { return next() }

  if (authStore.accessToken) {
    authStore.setAxios({ accessToken: authStore.accessToken })

    try {
      const res = await authStore.getAuth()
      if (res.error) {
        authStore.logout()

        return next({ name: 'login' })
      }

      authStore.setUser(res.data.user)
      authStore.setLogged()

      const permissions = (await authStore.getPermissions()).data.permissions
      authStore.setPermissions(permissions)

      if (to.name === 'login')
        return next({ name: 'dashboard' })

      return next()

    } catch (error) {
      authStore.logout()

      return next({ name: 'login' })
    }
  }

  if (to.name !== 'login')
    return next({ name: 'login' })

  return next()
})

router.afterEach(async (to) => {
  if (to.name === 'logout') {
    const authStore = useAuthStore()
    authStore.logout()
    router.replace('login')
  }
})

export default router;
